import React, {useState} from 'react';
import {Button, Table, Accordion, Card, Alert} from 'react-bootstrap';
import {useGames} from './useGames';
import {useHistory} from 'react-router-dom';

export function Games() {

  const {games, newGame, deleteGame, exportGames, importGames} = useGames();
  const [exportedGames, setExportedGames] = useState<string>();
  const [importErrors, setImportErrors] = useState<string[]>([]);
  const history = useHistory();

  function doImportGames() {
    try {
      importGames(exportedGames);
    }
    catch(error) {
      setImportErrors(importErrors => [...importErrors, 'Error: ' + error.message]);
    }
  }

  return (
    <>
      <h1>Spellen</h1>
      {games.length > 0 &&
        <Table striped hover>
          <thead>
          <tr>
            <th>Gemaakt</th>
            <th>Geüpdatet</th>
          </tr>
          </thead>
          <tbody>
          {games.map(({id, created, updated, scores}) => (
            <tr key={id} onClick={() => history.push(`/game/${id}`)}>
              <td>{new Date(created).toLocaleString()}</td>
              <td>{new Date(updated).toLocaleString()}</td>
              <td>
                { scores.length === 0 &&
                  <a href={'/'} onClick={e => {e.preventDefault(); e.stopPropagation(); deleteGame(id); }}>Verwijderen</a>
                }
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
      }
      <Button onClick={newGame}>Nieuw spel</Button>
      <br/><br/>
      <Accordion>
        <Card>
          <Accordion.Toggle eventKey={'0'} as={Card.Header}>Import/Export</Accordion.Toggle>
          <Accordion.Collapse eventKey={'0'}>
            <Card.Body>
              <Button variant='secondary' onClick={() => setExportedGames(exportGames())}>Exporteer</Button>&nbsp;
              <Button variant='secondary' onClick={doImportGames}>Importeer</Button>
              <br/><br/>
              <textarea style={{width: '100%'}} onChange={(text) => setExportedGames(text.target.value)} value={exportedGames}/>
              {importErrors.map((importError, index ) =>
                <Alert dismissible onClose={() => {setImportErrors(importErrors => [...importErrors.slice(0, index), ...importErrors.slice(index + 1)])}} variant={'danger'}>{importError}</Alert>
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
}