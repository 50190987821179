export const data = {
  players: ['Wim', 'Anneke', 'Lisette', 'Cor'],
  scores: [
    [[46,24,16,20], [0, 0]],
    [[28,42,10,6], [1, 3]],
    [[32, 46, 4, 20], [1, 2]],
    [[26, 18, 0, 20], [0, 1]],
    [[72,24,12,8], [0,0]],
    [[28,16,12,56], [3,3]],
    [[48,12,44,32], [-1,2]],
    [[32,12,32,144], [3,1]],
    [[160, 32, 20, 16], [0,0]],
    [[20, 12, 16, 12], [0,3]],
    [[14, 18, 250, 48], [2,2]],
    [[184, 112, 96, 36], [0,1]],
    [[46, 32, 20, 80], [0,0]],
    [[32, 12, 14, 48], [0,3]],
    [[32, 6, 72, 12], [2,2]],
    [[12, 12, 96, 48], [-1,1]],
  ]
};