import ScoreTable from './ScoreTable';
import React from 'react';
import {HashRouter, Switch, Route} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import {Games} from './Games';


export function App() {

  return (
    <Container fluid>
      <HashRouter>
        <Switch>
          <Route path={'/game/:id'} component={ScoreTable}/>
          <Route component={Games}/>
        </Switch>
      </HashRouter>
    </Container>
  );
}