import React, {useEffect, useState} from 'react';
import {data} from './data';
import { v4 as uuid} from 'uuid';
import createFragment from 'react-addons-create-fragment';
import {Button, Table} from 'react-bootstrap';
import {useParams, useHistory} from 'react-router-dom';
import {useGames} from './useGames';

export type scoresType = [number[], [number, number], string][];

function ScoreTable() {

  const params = useParams<any>();
  const history = useHistory();
  const [editRow, setEditRow] = useState(-1);
  const {games, updateGameById} = useGames();

  const game = games.find(game => game.id === params.id);
  const [scores, setScores] = useState<scoresType>(game ? game.scores : []);

  const id = game?.id ?? '';

  useEffect(() => {
    if (id) {
      updateGameById(id, {
        scores
      });
    }
  }, [id, scores, updateGameById]);

  if (!game) {
    return (
      <>
        <h1>Kan dit spel niet vinden</h1>
        <Button variant='secondary' onClick={() => history.push('/')}>Terug</Button>
      </>
    );
  }

  function toggleMeta(i: number, k: number, m: number) {
    setScores(scores => [
      ...scores.slice(0, i),
      [
        scores[i][0],
        [
          m !== 0 ? scores[i][1][0] : k === scores[i][1][0] ? -1 : k,
          m !== 1 ? scores[i][1][1] : k === scores[i][1][1] ? -1 : k,
        ],
        scores[i][2],
      ],
      ...scores.slice(i + 1),
    ]);
  }

  function addRow() {
    setScores(scores => [
        ...scores,
        [[0,0,0,0], [-1,-1], uuid()]
      ]
    );
    setEditRow(scores.length);
  }

  function deleteRow(i: number) {
    setScores(scores => [
        ...scores.slice(0, i),
        ...scores.slice(i + 1),
      ]
    );
  }

  function onDataEntry(valueStr: string, i: number, k: number) {
    const value = parseInt(valueStr);
    if (!isNaN(value) && value >= 0) {
      setScores(scores => [
        ...scores.slice(0, i),
        [
          [
            ...scores[i][0].slice(0, k),
            value,
            ...scores[i][0].slice(k + 1),
          ],
          scores[i][1],
          scores[i][2],
        ],
        ...scores.slice(i + 1),
      ]);
    }
  }

  const plus_minus = scores.map(([points, [mahjong, east]]) => {
    const settle = [0,0,0,0];

    points.forEach((point, i) => {
      if (i === mahjong) {
        settle[i] = point * (points.length - 1)
        if (i === east) {
          settle[i] *= 2;
        } else if (east > -1) {
          settle[i] += point;
        }
        // console.log(`${data.players[i]} krijgt Mahjong ${settle[i]}`)
      }
      else {
        if (mahjong > -1) {
          settle[i] = -points[mahjong]
          if (i === east || east === mahjong) {
            settle[i] *= 2;
          }
          // console.log(`${data.players[i]} betaalt Mahjong ${settle[i]} aan ${data.players[mahjong]}`)
        }
        points.forEach((point_j, k) => {
          if (k !== mahjong) {
            let diff = point - point_j;
            if (i === east || k === east) {
              diff *= 2;
            }
            // console.log(`${data.players[i]} krijgt ${diff} van ${data.players[k]}`)
            settle[i] += diff;
          }
        });
      }
    });

    if (settle.reduce((sum, x) => sum + x, 0) !== 0) {
      // console.error('Sum is not zero: ' + JSON.stringify(settle));
      throw Error('Sum is not zero: ' + JSON.stringify(settle));
    }
    // console.log(settle);
    return settle;
  });

  let cumulative: number[][] = [[0,0,0,0]];

  plus_minus.forEach((points, k) => {
    cumulative.push([0,0,0,0]);
    points.forEach((p, m) => {
      cumulative[k + 1][m] = cumulative[k][m] + p;
    });
  }, [[0, 0, 0, 0]]);

  cumulative = cumulative.slice(1);

  return (
    <>
      <h1>Mahjong score tabel</h1>
      {scores.length > 0 &&
        <div className="table-responsive">
          <Table striped>
            <thead>
            <tr>
              {data.players.map(player =>
                createFragment({
                  [player]: (
                    <>
                      <th>{player}</th>
                      <th>M</th>
                      <th>O</th>
                      <th>+/-</th>
                      <th>Res.</th>
                    </>
                  )
                })
              )}
              <th/>
              <th/>
            </tr>
            </thead>
            <tbody>
            {scores.map(([points, meta, id], i) =>
              <tr key={id}>
                {points.map((point, k) =>
                  createFragment({
                    [k + '_']: (
                      <>
                        <td>
                          <input type='number' disabled={editRow !== i} value={point}
                                 onInput={e => onDataEntry(e.currentTarget.value, i, k)}/>
                        </td>
                        <td><input type='checkbox' disabled={editRow !== i} checked={k === meta[0]}
                                   onChange={() => toggleMeta(i, k, 0)}/></td>
                        <td><input type='checkbox' disabled={editRow !== i} checked={k === meta[1]}
                                   onChange={() => toggleMeta(i, k, 1)}/></td>
                        <td className={'number'}>{plus_minus[i][k]}</td>
                        <td className={'number ' + (cumulative[i][k] < 0 ? 'negative' : 'positive')}>{cumulative[i][k]}</td>
                      </>
                    )
                  })
                )}
                <td>
                  {editRow === i ?
                    <a href='/' onClick={(e) => {
                      e.preventDefault();
                      setEditRow(-1);
                    }}>opslaan</a> :
                    editRow === -1 && <a href='/' onClick={(e) => {
                      e.preventDefault();
                      setEditRow(i);
                    }}>wijzigen</a>
                  }
                </td>
                <td>
                  {editRow !== i && editRow === -1 && points.every(point => point === 0) && meta[0] === -1 && meta[1] === -1 &&
                    <a href={'/'} onClick={e => {
                      e.preventDefault();
                      deleteRow(i);
                    }}>verwijderen</a>
                  }
                </td>
              </tr>
            )}
            </tbody>
          </Table>
        </div>
      }
      {scores.length > 0 &&
        <ul>
          <li>
            <b>M</b>: Mahjong
          </li>
          <li>
            <b>O</b>: Oost
          </li>
        </ul>
      }
      {editRow === -1 && <Button onClick={addRow}>Nieuwe ronde</Button>}
      <br/><br/>
      <Button variant='secondary' onClick={() => history.push('/')}>Terug</Button>
    </>
  );
}

export default ScoreTable;
